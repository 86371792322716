export default {
    mutations: {
        SET_LEVELS_QUESTIONS: 'SET_LEVELS_QUESTIONS',
        SET_LEVEL_ONE_REQUEST: 'SET_LEVEL_ONE_REQUEST',
        SET_LEVEL_ONE_REQUEST_DATA: 'SET_LEVEL_ONE_REQUEST_DATA',
        SET_LEVEL_TWO_REQUEST_DATA: 'SET_LEVEL_TWO_REQUEST_DATA',
        SET_LEVEL_ONE_REQUEST_STATUS: 'SET_LEVEL_ONE_REQUEST_STATUS',
        SET_AVAILABLE_RESID_COUNTRIES: 'SET_AVAILABLE_RESID_COUNTRIES',
        SET_MIGRATION_STATUS: 'SET_MIGRATION_STATUS',
        SET_LIMITATIONS_BY_COUNTRY: 'SET_LIMITATIONS_BY_COUNTRY',
        SET_VERIF_LEVEL_REQUIREMENTS: 'SET_VERIF_LEVEL_REQUIREMENTS',
        SET_WHOLESALEPARTNER_REQUIREMENTS: 'SET_WHOLESALEPARTNER_REQUIREMENTS',
        SET_MIGRATED_LEVEL_ONE_DATA: 'SET_MIGRATED_LEVEL_ONE_DATA',
        RESET_VERIF_LEVEL_DATA: 'RESET_VERIF_LEVEL_DATA',
    },
    getters: {
        GET_LEVELS_QUESTIONS: 'GET_LEVELS_QUESTIONS',
        GET_LEVEL_ONE_REQUEST: 'GET_LEVEL_ONE_REQUEST',
        GET_LEVEL_ONE_REQUEST_STATUS: 'GET_LEVEL_ONE_REQUEST_STATUS',
        GET_LEVEL_TWO_REQUEST_STATUS: 'GET_LEVEL_TWO_REQUEST_STATUS',
        GET_LEVEL_TWO_REQUEST: 'GET_LEVEL_TWO_REQUEST',
        GET_AVAILABLE_RESID_COUNTRIES: 'GET_AVAILABLE_RESID_COUNTRIES',
        GET_LIMITATIONS_BY_COUNTRY: 'GET_LIMITATIONS_BY_COUNTRY',
        GET_MIGRATION_STATUS: 'GET_MIGRATION_STATUS',
        GET_VERIF_LEVEL_REQUIREMENTS: 'GET_VERIF_LEVEL_REQUIREMENTS',
        GET_WHOLESALEPARTNER_REQUIREMENTS: 'GET_WHOLESALEPARTNER_REQUIREMENTS',
        GET_MIGRATED_LEVEL_ONE_DATA: 'GET_MIGRATED_LEVEL_ONE_DATA',
    },
    actions: {
        FETCH_WHOLESALEPARTNER_REQUIREMENTS: 'FETCH_WHOLESALEPARTNER_REQUIREMENTS',
        FETCH_LEVELS_QUESTIONS: 'FETCH_LEVELS_QUESTIONS',
        REQUEST_LEVEL_ONE: 'REQUEST_LEVEL_ONE',
        UPDATE_CLIENT_PHONE: 'UPDATE_CLIENT_PHONE',
        UPDATE_LEVEL_ONE_INFO: 'UPDATE_LEVEL_ONE_INFO',
        SAVE_EXTRA_INFO_THIRD_MODAL: 'SAVE_EXTRA_INFO_THIRD_MODAL',
        REQUEST_LEVEL_TWO: 'REQUEST_LEVEL_TWO',
        REQUEST_WHOLESALE_PARTNER: 'REQUEST_WHOLESALE_PARTNER',
        FETCH_AVAILABLE_RESID_COUNTRIES: 'FETCH_AVAILABLE_RESID_COUNTRIES',
        FETCH_MIGRATION_STATUS: 'FETCH_MIGRATION_STATUS',
        FETCH_LIMITATION_BY_COUNTRY: 'FETCH_LIMITATION_BY_COUNTRY',
        FETCH_VERIF_LEVEL_REQUIREMENTS: 'FETCH_VERIF_LEVEL_REQUIREMENTS',
        FETCH_MIGRATED_LEVEL_ONE_DATA: 'FETCH_MIGRATED_LEVEL_ONE_DATA',
    },
};