export const countries = {
    "AF": {
        "es": "Afganistán",
        "en": "Afghanistan"
    },
    "AL": {
        "es": "Albania",
        "en": "Albania"
    },
    "DZ": {
        "es": "Argelia",
        "en": "Algeria"
    },
    "AD": {
        "es": "Andorra",
        "en": "Andorra"
    },
    "AO": {
        "es": "Angola",
        "en": "Angola"
    },
    "AG": {
        "es": "Antigua y Barbuda",
        "en": "Antigua and Barbuda"
    },
    "AR": {
        "es": "Argentina",
        "en": "Argentina"
    },
    "AM": {
        "es": "Armenia",
        "en": "Armenia"
    },
    "AU": {
        "es": "Australia",
        "en": "Australia"
    },
    "AT": {
        "es": "Austria",
        "en": "Austria"
    },
    "AZ": {
        "es": "Azerbaiyán",
        "en": "Azerbaijan"
    },
    "BS": {
        "es": "Bahamas",
        "en": "Bahamas"
    },
    "BH": {
        "es": "Baréin",
        "en": "Bahrain"
    },
    "BD": {
        "es": "Bangladesh",
        "en": "Bangladesh"
    },
    "BB": {
        "es": "Barbados",
        "en": "Barbados"
    },
    "BY": {
        "es": "Bielorrusia",
        "en": "Belarus"
    },
    "BE": {
        "es": "Bélgica",
        "en": "Belgium"
    },
    "BZ": {
        "es": "Belice",
        "en": "Belize"
    },
    "BJ": {
        "es": "Benín",
        "en": "Benin"
    },
    "BT": {
        "es": "Bután",
        "en": "Bhutan"
    },
    "BO": {
        "es": "Bolivia",
        "en": "Bolivia"
    },
    "BA": {
        "es": "Bosnia y Herzegovina",
        "en": "Bosnia and Herzegovina"
    },
    "BW": {
        "es": "Botsuana",
        "en": "Botswana"
    },
    "BR": {
        "es": "Brasil",
        "en": "Brazil"
    },
    "BN": {
        "es": "Brunéi",
        "en": "Brunei"
    },
    "BG": {
        "es": "Bulgaria",
        "en": "Bulgaria"
    },
    "BF": {
        "es": "Burkina Faso",
        "en": "Burkina Faso"
    },
    "BI": {
        "es": "Burundi",
        "en": "Burundi"
    },
    "CV": {
        "es": "Cabo Verde",
        "en": "Cabo Verde"
    },
    "KH": {
        "es": "Camboya",
        "en": "Cambodia"
    },
    "CM": {
        "es": "Camerún",
        "en": "Cameroon"
    },
    "CA": {
        "es": "Canadá",
        "en": "Canada"
    },
    "CF": {
        "es": "República Centroafricana",
        "en": "Central African Republic"
    },
    "TD": {
        "es": "Chad",
        "en": "Chad"
    },
    "CL": {
        "es": "Chile",
        "en": "Chile"
    },
    "CN": {
        "es": "China",
        "en": "China"
    },
    "CO": {
        "es": "Colombia",
        "en": "Colombia"
    },
    "KM": {
        "es": "Comoras",
        "en": "Comoros"
    },
    "CG": {
        "es": "Congo",
        "en": "Congo"
    },
    "CR": {
        "es": "Costa Rica",
        "en": "Costa Rica"
    },
    "HR": {
        "es": "Croacia",
        "en": "Croatia"
    },
    "CU": {
        "es": "Cuba",
        "en": "Cuba"
    },
    "CY": {
        "es": "Chipre",
        "en": "Cyprus"
    },
    "CZ": {
        "es": "Chequia",
        "en": "Czechia"
    },
    "DK": {
        "es": "Dinamarca",
        "en": "Denmark"
    },
    "DJ": {
        "es": "Yibuti",
        "en": "Djibouti"
    },
    "DM": {
        "es": "Dominica",
        "en": "Dominica"
    },
    "DO": {
        "es": "República Dominicana",
        "en": "Dominican Republic"
    },
    "EC": {
        "es": "Ecuador",
        "en": "Ecuador"
    },
    "EG": {
        "es": "Egipto",
        "en": "Egypt"
    },
    "SV": {
        "es": "El Salvador",
        "en": "El Salvador"
    },
    "GQ": {
        "es": "Guinea Ecuatorial",
        "en": "Equatorial Guinea"
    },
    "ER": {
        "es": "Eritrea",
        "en": "Eritrea"
    },
    "EE": {
        "es": "Estonia",
        "en": "Estonia"
    },
    "SZ": {
        "es": "Esuatini",
        "en": "Eswatini"
    },
    "ET": {
        "es": "Etiopía",
        "en": "Ethiopia"
    },
    "FJ": {
        "es": "Fiyi",
        "en": "Fiji"
    },
    "FI": {
        "es": "Finlandia",
        "en": "Finland"
    },
    "FR": {
        "es": "Francia",
        "en": "France"
    },
    "GA": {
        "es": "Gabón",
        "en": "Gabon"
    },
    "GM": {
        "es": "Gambia",
        "en": "Gambia"
    },
    "GE": {
        "es": "Georgia",
        "en": "Georgia"
    },
    "DE": {
        "es": "Alemania",
        "en": "Germany"
    },
    "GH": {
        "es": "Ghana",
        "en": "Ghana"
    },
    "GR": {
        "es": "Grecia",
        "en": "Greece"
    },
    "GD": {
        "es": "Granada",
        "en": "Grenada"
    },
    "GT": {
        "es": "Guatemala",
        "en": "Guatemala"
    },
    "GN": {
        "es": "Guinea",
        "en": "Guinea"
    },
    "GW": {
        "es": "Guinea-Bisáu",
        "en": "Guinea-Bissau"
    },
    "GY": {
        "es": "Guyana",
        "en": "Guyana"
    },
    "HT": {
        "es": "Haití",
        "en": "Haiti"
    },
    "HN": {
        "es": "Honduras",
        "en": "Honduras"
    },
    "HU": {
        "es": "Hungría",
        "en": "Hungary"
    },
    "IS": {
        "es": "Islandia",
        "en": "Iceland"
    },
    "IN": {
        "es": "India",
        "en": "India"
    },
    "ID": {
        "es": "Indonesia",
        "en": "Indonesia"
    },
    "IR": {
        "es": "Irán",
        "en": "Iran"
    },
    "IQ": {
        "es": "Irak",
        "en": "Iraq"
    },
    "IE": {
        "es": "Irlanda",
        "en": "Ireland"
    },
    "IL": {
        "es": "Israel",
        "en": "Israel"
    },
    "IT": {
        "es": "Italia",
        "en": "Italy"
    },
    "JM": {
        "es": "Jamaica",
        "en": "Jamaica"
    },
    "JP": {
        "es": "Japón",
        "en": "Japan"
    },
    "JO": {
        "es": "Jordania",
        "en": "Jordan"
    },
    "KZ": {
        "es": "Kazajistán",
        "en": "Kazakhstan"
    },
    "KE": {
        "es": "Kenia",
        "en": "Kenya"
    },
    "KI": {
        "es": "Kiribati",
        "en": "Kiribati"
    },
    "KP": {
        "es": "Corea del Norte",
        "en": "North Korea"
    },
    "KR": {
        "es": "Corea del Sur",
        "en": "South Korea"
    },
    "KW": {
        "es": "Kuwait",
        "en": "Kuwait"
    },
    "KG": {
        "es": "Kirguistán",
        "en": "Kyrgyzstan"
    },
    "LA": {
        "es": "Laos",
        "en": "Laos"
    },
    "LV": {
        "es": "Letonia",
        "en": "Latvia"
    },
    "LB": {
        "es": "Líbano",
        "en": "Lebanon"
    },
    "LS": {
        "es": "Lesoto",
        "en": "Lesotho"
    },
    "LR": {
        "es": "Liberia",
        "en": "Liberia"
    },
    "LY": {
        "es": "Libia",
        "en": "Libya"
    },
    "LI": {
        "es": "Liechtenstein",
        "en": "Liechtenstein"
    },
    "LT": {
        "es": "Lituania",
        "en": "Lithuania"
    },
    "LU": {
        "es": "Luxemburgo",
        "en": "Luxembourg"
    },
    "MG": {
        "es": "Madagascar",
        "en": "Madagascar"
    },
    "MW": {
        "es": "Malaui",
        "en": "Malawi"
    },
    "MY": {
        "es": "Malasia",
        "en": "Malaysia"
    },
    "MV": {
        "es": "Maldivas",
        "en": "Maldives"
    },
    "ML": {
        "es": "Malí",
        "en": "Mali"
    },
    "MT": {
        "es": "Malta",
        "en": "Malta"
    },
    "MH": {
        "es": "Islas Marshall",
        "en": "Marshall Islands"
    },
    "MR": {
        "es": "Mauritania",
        "en": "Mauritania"
    },
    "MU": {
        "es": "Mauricio",
        "en": "Mauritius"
    },
    "MX": {
        "es": "México",
        "en": "Mexico"
    },
    "FM": {
        "es": "Micronesia",
        "en": "Micronesia"
    },
    "MD": {
        "es": "Moldavia",
        "en": "Moldova"
    },
    "MC": {
        "es": "Mónaco",
        "en": "Monaco"
    },
    "MN": {
        "es": "Mongolia",
        "en": "Mongolia"
    },
    "ME": {
        "es": "Montenegro",
        "en": "Montenegro"
    },
    "MA": {
        "es": "Marruecos",
        "en": "Morocco"
    },
    "MZ": {
        "es": "Mozambique",
        "en": "Mozambique"
    },
    "MM": {
        "es": "Birmania",
        "en": "Myanmar"
    },
    "NA": {
        "es": "Namibia",
        "en": "Namibia"
    },
    "NR": {
        "es": "Nauru",
        "en": "Nauru"
    },
    "NP": {
        "es": "Nepal",
        "en": "Nepal"
    },
    "NL": {
        "es": "Países Bajos",
        "en": "Netherlands"
    },
    "NZ": {
        "es": "Nueva Zelanda",
        "en": "New Zealand"
    },
    "NI": {
        "es": "Nicaragua",
        "en": "Nicaragua"
    },
    "NE": {
        "es": "Níger",
        "en": "Niger"
    },
    "NG": {
        "es": "Nigeria",
        "en": "Nigeria"
    },
    "MK": {
        "es": "Macedonia del Norte",
        "en": "North Macedonia"
    },
    "NO": {
        "es": "Noruega",
        "en": "Norway"
    },
    "OM": {
        "es": "Omán",
        "en": "Oman"
    },
    "PK": {
        "es": "Pakistán",
        "en": "Pakistan"
    },
    "PW": {
        "es": "Palaos",
        "en": "Palau"
    },
    "PS": {
        "es": "Palestina",
        "en": "Palestine"
    },
    "PA": {
        "es": "Panamá",
        "en": "Panama"
    },
    "PG": {
        "es": "Papúa Nueva Guinea",
        "en": "Papua New Guinea"
    },
    "PY": {
        "es": "Paraguay",
        "en": "Paraguay"
    },
    "PE": {
        "es": "Perú",
        "en": "Peru"
    },
    "PH": {
        "es": "Filipinas",
        "en": "Philippines"
    },
    "PL": {
        "es": "Polonia",
        "en": "Poland"
    },
    "PT": {
        "es": "Portugal",
        "en": "Portugal"
    },
    "QA": {
        "es": "Catar",
        "en": "Qatar"
    },
    "RO": {
        "es": "Rumania",
        "en": "Romania"
    },
    "RU": {
        "es": "Rusia",
        "en": "Russia"
    },
    "RW": {
        "es": "Ruanda",
        "en": "Rwanda"
    },
    "KN": {
        "es": "San Cristóbal y Nieves",
        "en": "Saint Kitts and Nevis"
    },
    "LC": {
        "es": "Santa Lucía",
        "en": "Saint Lucia"
    },
    "VC": {
        "es": "San Vicente y las Granadinas",
        "en": "Saint Vincent and the Grenadines"
    },
    "WS": {
        "es": "Samoa",
        "en": "Samoa"
    },
    "SM": {
        "es": "San Marino",
        "en": "San Marino"
    },
    "ST": {
        "es": "Santo Tomé y Príncipe",
        "en": "Sao Tome and Principe"
    },
    "SA": {
        "es": "Arabia Saudita",
        "en": "Saudi Arabia"
    },
    "SN": {
        "es": "Senegal",
        "en": "Senegal"
    },
    "RS": {
        "es": "Serbia",
        "en": "Serbia"
    },
    "SC": {
        "es": "Seychelles",
        "en": "Seychelles"
    },
    "SL": {
        "es": "Sierra Leona",
        "en": "Sierra Leone"
    },
    "SG": {
        "es": "Singapur",
        "en": "Singapore"
    },
    "SK": {
        "es": "Eslovaquia",
        "en": "Slovakia"
    },
    "SI": {
        "es": "Eslovenia",
        "en": "Slovenia"
    },
    "SB": {
        "es": "Islas Salomón",
        "en": "Solomon Islands"
    },
    "SO": {
        "es": "Somalia",
        "en": "Somalia"
    },
    "ZA": {
        "es": "Sudáfrica",
        "en": "South Africa"
    },
    "SS": {
        "es": "Sudán del Sur",
        "en": "South Sudan"
    },
    "ES": {
        "es": "España",
        "en": "Spain"
    },
    "LK": {
        "es": "Sri Lanka",
        "en": "Sri Lanka"
    },
    "SD": {
        "es": "Sudán",
        "en": "Sudan"
    },
    "SR": {
        "es": "Surinam",
        "en": "Suriname"
    },
    "SE": {
        "es": "Suecia",
        "en": "Sweden"
    },
    "CH": {
        "es": "Suiza",
        "en": "Switzerland"
    },
    "SY": {
        "es": "Siria",
        "en": "Syria"
    },
    "TW": {
        "es": "Taiwán",
        "en": "Taiwan"
    },
    "TJ": {
        "es": "Tayikistán",
        "en": "Tajikistan"
    },
    "TZ": {
        "es": "Tanzania",
        "en": "Tanzania"
    },
    "TH": {
        "es": "Tailandia",
        "en": "Thailand"
    },
    "TL": {
        "es": "Timor Oriental",
        "en": "Timor-Leste"
    },
    "TG": {
        "es": "Togo",
        "en": "Togo"
    },
    "TO": {
        "es": "Tonga",
        "en": "Tonga"
    },
    "TT": {
        "es": "Trinidad y Tobago",
        "en": "Trinidad and Tobago"
    },
    "TN": {
        "es": "Túnez",
        "en": "Tunisia"
    },
    "TR": {
        "es": "Turquía",
        "en": "Turkey"
    },
    "TM": {
        "es": "Turkmenistán",
        "en": "Turkmenistan"
    },
    "TV": {
        "es": "Tuvalu",
        "en": "Tuvalu"
    },
    "UG": {
        "es": "Uganda",
        "en": "Uganda"
    },
    "UA": {
        "es": "Ucrania",
        "en": "Ukraine"
    },
    "AE": {
        "es": "Emiratos Árabes Unidos",
        "en": "United Arab Emirates"
    },
    "GB": {
        "es": "Reino Unido",
        "en": "United Kingdom"
    },
    "US": {
        "es": "Estados Unidos",
        "en": "United States"
    },
    "UY": {
        "es": "Uruguay",
        "en": "Uruguay"
    },
    "UZ": {
        "es": "Uzbekistán",
        "en": "Uzbekistan"
    },
    "VU": {
        "es": "Vanuatu",
        "en": "Vanuatu"
    },
    "VE": {
        "es": "Venezuela",
        "en": "Venezuela"
    },
    "VN": {
        "es": "Vietnam",
        "en": "Vietnam"
    },
    "YE": {
        "es": "Yemen",
        "en": "Yemen"
    },
    "ZM": {
        "es": "Zambia",
        "en": "Zambia"
    },
    "ZW": {
        "es": "Zimbabue",
        "en": "Zimbabwe"
    }
}
