import { NationalityCountry } from "@/modules/countries/interfaces/countries.interface";
import { Question } from "./questions.interface";

export interface ReqLevelTwo {
    email_user: {
        value: string
    },
    funds_source?: {
        value: Array<any>,
        rejected: boolean
    },
    residency_proof: {
        value: any,
        rejected: boolean
    },
    job_title?: {
        value: string,
        rejected: boolean
    },
    account_uses?:{
        value: Array<any>,
        rejected: boolean
    },
    monthly_amount?:{
        value: Array<any>,
        rejected: boolean
    },
    work_industry?:{
        value: Array<any>,
        rejected: boolean
    },
    other_industry?:{
        value: string,
        rejected: boolean
    },
    answers: Question[]
}

export interface ReqLevelOne {
    date_birth: {
        value: string,
        rejected: boolean
    },
    id_nationality_country: {
        value: number | string,
        rejected: boolean
    },
    main_phone: {
        value: string,
        rejected: boolean
    },
    main_phone_code: {
        value: string,
        rejected: boolean
    },
    main_phone_full: {
        value: string,
        rejected: boolean
    },
    gender: {
        value: string,
        rejected: boolean
    },
    state_name: {
        value: string,
        rejected: boolean
    },
    resid_city: {
        value: string,
        rejected: boolean
    },
    pol_exp_per: {
        value: boolean,
        rejected: boolean
    },
    truthful_information: {
        value: boolean,
        rejected: boolean
    },
    lawful_funds: {
        value: boolean,
        rejected: boolean
    },
    ok_legal_terms?: {
        value: boolean,
        rejected: boolean
    }
    email_user: {
        value: string,
        rejected: boolean
    },
    id_ident_doc_type: {
        value: number,
        rejected: boolean
    },
    id_country: {
        value: number,
        rejected: boolean
    },
    ident_doc_number: {
        value: string,
        rejected: boolean
    },
    occupation: {
        value: string,
        rejected: boolean
    },
    doc: {
        value: any,
        rejected: boolean
    },
    selfie: {
        value: any,
        rejected: boolean
    },
    main_sn_platf: {
        value: string,
        rejected: boolean
    },
    user_main_sn_platf: {
        value: string,
        rejected: boolean
    },
    domicile_address:{
        value: string,
        rejected: boolean
    },
    new_password?:{
        value: string,
        rejected: null
    },
    new_email?:{
        value: string,
        rejected: null
    },
    migrated?: boolean,
    residCountry?: NationalityCountry
}

export interface EditPhone {
    uuid_user: string,
    main_phone: string,
    main_phone_code: string,
    main_phone_full: string
}

export interface UpdateLevelOneInfo
{
    email_user: string,
    state_name: string,
    resid_city: string,
    occupation: string,
    address: string,
}

export interface SaveExtraInfoThirdModal
{
    id_user: number,
    industry: string,
    salary_range: string,
}

export interface ReqWholeSaleParter {
    email_user: string,
    reasons: string,
    strenghts: string
    remittance_service: boolean | string,
    old_resid_client_countries: Array<number | string> | string,	
    old_resid_client_countries_array: Array<any>,	
    profession : string,	
    resid_country : number | string,
    migration_status: number | string,
    new_resid_client_countries: Array<number | string> | string,	
    new_resid_client_countries_array: Array<any>,	
    clients_number : number,	
    monthly_amount : string,	
    monetary_growth : string,	
    clients_growth : number,	
    bussiness_name : string,	
    web_page_exp : boolean | string,	
    logo : boolean | string,
    reasons_status: boolean,
    strenghts_status: boolean,
    remittance_service_status: boolean,
    old_resid_client_countries_status: boolean,
    profession_status: boolean,
    resid_country_status: boolean,
    migration_status_status: boolean,
    new_resid_client_countries_status: boolean,
    clients_number_status: boolean,
    monthly_amount_status: boolean,
    monetary_growth_status: boolean,
    clients_growth_status: boolean,
    bussiness_name_status: boolean,
    web_page_exp_status: boolean,
    logo_status: boolean	
}

export enum RequestStatus {
    'UNDER_REVIEW' = 'UNDER_REVIEW',
    'REJECTED' = 'REJECTED',
    'APPROVED' = 'APPROVED',
    'BLANK' = 'BLANK'
}
