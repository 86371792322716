import { clearUser, USER_EMPTY_STATE } from "@/modules/auth/emptyStates/user.emptyState"
import store from "@/store"
import { auth } from "@/store/namespaces"
import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { countries } from '@/utils/country-names'
import AuthMethods from '@/store/auth/methods/auth.methods'

Vue.use(VueRouter)

function isInAmerica(country: string) {
    const americaCountries = [
        'AR',
        'BO',
        'BR',
        'CL',
        'CO',
        'CR',
        'CU',
        'DO',
        'EC',
        'SV',
        'GT',
        'HN',
        'MX',
        'NI',
        'PA',
        'PY',
        'PE',
        'PR',
        'UY',
        'VE',
        'CA',
        'US',
        'AG',
        'BS',
        'BB',
        'BZ',
        'DM',
        'GD',
        'HT',
        'JM',
        'KN',
        'LC',
        'VC',
        'TT',
        'SR',
        'GY',
    ];
    return americaCountries.includes(country);
}

function baseUrl() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
        return 'localhost:8080';
    }
  let env = hostname.split('.')[0]; // Extrae el subdominio (dev, test, etc.)
  if (!env.includes('bithonor'))
  {
    env += '.bithonor';
  }
    return `${env}`;
}

function latamUrl() {
    const hostname = window.location.hostname;
    return hostname.includes('localhost')
        ? `http://${baseUrl()}/inicio-sesion`
        : `https://${baseUrl()}.com/inicio-sesion`;
}

function europeUrl() {
    const hostname = window.location.hostname;
    return hostname.includes('localhost')
        ? `http://${baseUrl()}/inicio-sesion`
        : `https://${baseUrl()}.es/inicio-sesion`;
}

const europeversion = process.env.VUE_APP_EUROPE_VERSION
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'CountrySelection',
    component: () => import(`../views/CountrySelection.vue`),
    meta:{
       title: 'Bithonor',
       landingPage: true,
       description: 'Envía dinero a Venezuela y 7 países más de forma rápida y segura. Calculadora disponible. Regístrate para tasas competitivas'
      },
  },
  {
    path: '/inicio',
    name: 'Home',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}Home.vue`),
    meta:{
       title: 'Bithonor | Inicio',
       landingPage: true,
       description: 'Envía dinero a Venezuela y 7 países más de forma rápida y segura. Calculadora disponible. Regístrate para tasas competitivas'
      },
  },
  {
    path: '/nosotros',
    name: 'Us',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}Us.vue`),
    meta:{
        // authBlocked: true
       title: 'Bithonor | Nosotros',
       landingPage: true,
       description: 'Somos una plataforma financiera para migrantes y nómadas. Servicios financieros para migrantes que promueven la inclusión financiera a través de una plataforma sin fronteras.'
      },
    //redirect: '/inicio-sesion'
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(`../modules/landingPage/views/Blog.vue`),
    meta:{
        // authBlocked: true
       title: 'Bithonor | Blog',
       landingPage: true,
       description: 'Explora el Bithonor Blog para obtener respuestas y consejos sobre envíos de dinero internacionales. Descubre cómo usar Bithonor, respuestas a preguntas frecuentes y nuestros servicios.'
      },
    //redirect: '/inicio-sesion'
  },
  {
    path: '/blog/:id',
    name: 'Blog',
    component: () => import(`../modules/landingPage/views/BlogPost.vue`),
    meta:{
        // authBlocked: true
       title: 'Bithonor | Blog',
       landingPage: true,
        dynamicDescription: true
      },
    //redirect: '/inicio-sesion'
  },
  // {
  //   path: '/gana',
  //   name: 'Referrals',
  //   component: () => import('../modules/landingPage/views/Referrals.vue'),
  //   meta:{
  //       // authBlocked: true
  //      title: 'Bithonor | Gana'
  //     },
  //   //redirect: '/inicio-sesion'
  // },
  {
    path: '/contacto',
    name: 'Contacts',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}Contacts.vue`),
    meta:{
        // authBlocked: true
       title: 'Bithonor | Contacto',
       landingPage: true,
       description: 'Contáctanos para resolver tus dudas e inquietudes. Nuestro equipo está disponible para atenderte. Déjanos tu mensaje por WhatsApp o correo electrónico. '
      },
    //redirect: '/inicio-sesion'
  },
  {
    path: '/legal/politicas-de-privacidad',
    name: 'PrivacyPolicies',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}legal/PrivacyPolicies.vue`),
    meta:{
       title: 'Bithonor | Políticas de Privacidad',
       landingPage: true
    },
  },
  {
    path: '/legal/politicas-de-cookies',
    name: 'CookiesPolicies',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}legal/CookiesPolicies.vue`),
    meta:{
       title: 'Bithonor | Políticas de Cookies',
       landingPage: true
    },
  },
  {
    path: '/legal/terminos-y-condiciones',
    name: 'TermsAndConditions',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}legal/TermsAndConditions.vue`),
    meta:{
       title: 'Bithonor | Términos y condiciones',
       landingPage: true
    },
  },
  {
    path: '/legal/politicas-de-cumplimiento',
    name: 'CompliancePolicies',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}legal/CompliancePolicies.vue`),
    meta:{
       title: 'Bithonor | Políticas de Cookies',
       landingPage: true
    },
  },
  {
    path: '/legal/aviso-legal',
    name: 'LegalWarning',
    component: () => import(`../modules/landingPage/views${europeversion ? '/eu/' : '/'}legal/LegalWarning.vue`),
    meta:{
       title: 'Bithonor | Aviso Legal',
       landingPage: true
    },
  },
  {
    path: '/registro',
    name: 'CreateAccount',
    component: () => import('../modules/auth/views/baseAuth/CreateAccount.vue'),
    meta:{
       nextRoute: '/registro/exitoso',
       authBlocked: true,
       title: 'Crear cuenta | Bithonor',
       description: 'Crea tu cuenta en Bithonor y envía dinero a Venezuela y más de 7 países. Ingresa tus datos personales y verifica tu correo electrónico. ¡Regístrate ahora!'
    }
  },
  
  // {
  //   path: '/registro/datos',
  //   name: 'LevelCero',
  //   component: () => import('../modules/auth/views/baseAuth/LevelCero.vue'),
  //   meta:{
  //     requiresPreceding: true,
  //     firstStep: '/registro/pais-de-residencia',
  //     nextRoute: '/registro/verificacion',
  //     authBlocked: true,
  //     title: 'Crear cuenta | Bithonor'
  //   }
  // },
  // {
  //   path: '/registro/verificacion',
  //   name: 'LevelCeroVerification',
  //   component: () => import('../modules/auth/views/baseAuth/LevelCeroVerification.vue'),
  //   meta:{
  //     requiresPreceding: true,
  //     firstStep: '/registro/pais-de-residencia',
  //     prevRoute: '/registro/datos',
  //     nextRoute: '/registro/exitoso',
  //     authBlocked: true,
  //     title: 'Verificar cuenta | Bithonor'
  //   }
  // },
  {
    path: '/registro/exitoso',
    name: 'RegisterSuccess',
    component: () => import('../modules/auth/views/baseAuth/RegisterSuccess.vue'),
    meta:{
      requiresPreceding: false,
      firstStep: '/registro',
      authBlocked: false,
      title: 'Creación de cuenta exitosa | Bithonor'
    }
  },
  {
    path: '/inicio-sesion',
    name: 'Login',
    component: () => import('../modules/auth/views/baseAuth/Login.vue'),
    meta:{
      authBlocked: true,
      title: 'Inicio de sesión | Bithonor',
      description: 'Inicia sesión en Bithonor con tu correo electrónico y contraseña. Si olvidaste tu contraseña, te ayudamos a recuperarla. ¿No tienes cuenta? Regístrate ahora.'
    }
  },
  {
    path: '/inicio-sesion/fallido',
    name: 'UserBlocked',
    component: () => import('../modules/auth/views/baseAuth/UserBlocked.vue'),
    meta:{
      authBlocked: true,
      title: 'Usuario bloqueado | Bithonor'
    }
  },
  {
    path: '/inicio-sesion/olvido-contrasena/envio-correo',
    name: 'SendEmail',
    component: () => import('../modules/auth/views/forgotPassword/SendEmail.vue'),
    meta:{
     nextRoute: '/inicio-sesion/olvido-contrasena/envio-correo/exitoso',
     authBlocked: true,
     title: 'Olvido de contraseña | Bithonor'
    }
  },
  {
    path: '/inicio-sesion/olvido-contrasena/envio-correo/exitoso',
    name: 'SendEmailSuccess',
    component: () => import('../modules/auth/views/forgotPassword/SendEmailSuccess.vue'),
    meta:{
      requiresPreceding: true,
      firstStep: '/inicio-sesion/olvido-contrasena/envio-correo',
      nextRoute: '/inicio-sesion/olvido-contrasena/recuperar-contrasena',
      authBlocked: true,
      title: 'Olvido de contraseña | Bithonor'
    }
  },
  {
    path: '/inicio-sesion/olvido-contrasena/recuperar-contrasena',
    name: 'RecoverPassword',
    component: () => import('../modules/auth/views/forgotPassword/RecoverPassword.vue'),
    meta:{
      requiresPreceding: true,
      firstStep: '/inicio-sesion/olvido-contrasena/envio-correo',
      authBlocked: true,
      title: 'Recuperar contraseña | Bithonor'
    }
  },
  {
    path: '/cerrar-sesion',
    name: 'LogOut',
    component: () => import('../modules/auth/views/baseAuth/Logout.vue'),
    meta:{
      title: 'Cierre de sesión | Bithonor',
      authBlocked: true,
    }
  },
  {
    path: '/cerrar-sesion/sesion-detectada',
    name: 'ActiveSession',
    component: () => import('../modules/auth/views/baseAuth/ActiveSession.vue'),
    meta:{
      title: 'Cierre de sesión | Bithonor',
      // authBlocked: true,
    }
  },
  {
    path: '/cerrar-sesion/sesion-detectada/cierre',
    name: 'ActiveSessionDetected',
    component: () => import('../modules/auth/views/baseAuth/ActiveSessionDetected.vue'),
    meta:{
      title: 'Cierre de sesión | Bithonor',
    }
  },
  {
    path: '/tablero',
    name: 'Dashboard',
    component: () => import('../modules/client/views/Dashboard.vue'),
    meta:{
      requiresAuth: true,
      baseLayout: true,
      title: 'Dashboard | Bithonor'
    }
  },
  // {
  //   path: '/tablero/intercambios',
  //   name: 'ExchangeDashboard',
  //   component: () => import('../modules/exchange/views/ExchangeDashboard.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Intercambios | Bithonor'
  //   }
  // },
  // {
  //   path: '/intercambios/compra',
  //   name: 'CryptoBuy',
  //   component: () => import('../modules/exchange/views/CryptoBuy.vue'),
  //   meta:{
  //     // requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Compra  | Bithonor'
  //   }
  // },
  // {
  //   path: '/intercambios/venta',
  //   name: 'CryptoSell',
  //   component: () => import('../modules/exchange/views/CryptoSell.vue'),
  //   meta:{
  //     // requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Venta  | Bithonor'
  //   }
  // },
  {
    path: '/perfil',
    name: 'Profile',
    component: () => import('../modules/client/views/Profile.vue'),
    meta:{
      requiresAuth: true,
      baseLayout: true,
      title: 'Perfil | Bithonor'
    }
  },
  {
    path: '/perfil/cambiar-contrasena',
    name: 'ChangePassword',
    component: () => import('../modules/client/views/ChangePassword.vue'),
    meta:{
     requiresAuth: true,
     baseLayout: true,
     title: 'Cambio de contraseña | Bithonor'
    }
  },
  {
    path: '/niveles-de-verificacion/limitaciones',
    name: 'VerificationLevelsFeatures',
    component: () => import('../modules/verificationLevels/views/VerificationLevelsFeatures.vue'),
    meta:{
     requiresAuth: true,
     baseLayout: true,
     title: 'Niveles de verificación | Bithonor'
    }
  },
  // {
  //   path: '/niveles-de-verificacion/nivel-intermedio',
  //   name: 'LevelOne',
  //   component: () => import('../modules/verificationLevels/views/LevelOne.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Verificación de datos | Bithonor'
  //   },
    // children:[
    //   // {
    //   //   path: 'exitoso',
    //   //   name: 'LevelOneSuccess',
    //   //   component: () => import('../modules/auth/views/verificationLevels/LevelOneSuccess.vue'),
    //   //   meta:{
    //   //    // requiresAuth: true,
    //   //   }
    //   // }
    // ]
  // },
  {
    path: '/niveles-de-verificacion/solicitud-exitosa',
    name: 'LevelSuccess',
    component: () => import('../modules/verificationLevels/views/LevelSuccess.vue'),
    meta:{
      requiresAuth: true,
      title: 'Solicitud exitosa | Bithonor'
    }
  },
  {
    path: '/niveles-de-verificacion/nivel-avanzado',
    name: 'LevelTwo',
    component: () => import('../modules/verificationLevels/views/LevelTwo.vue'),
    meta:{
      requiresAuth: true,
      baseLayout: true,
      title: 'Verificación de datos | Bithonor'
    }
  },
  // {
  //   path: '/chat',
  //   name: 'Chat',
  //   component: () => import('../modules/chat/views/Chat.vue'),
  //   meta:{
  //     // requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Chat | Bithonor'
  //   }
  // },
  // {
  //   path: '/asociado-mayorista',
  //   name: 'WholeSalePartner',
  //   component: () => import('../modules/verificationLevels/views/WholeSalePartner.vue'),
  //   meta:{
  //    requiresAuth: true,
  //    baseLayout: true,
  //     title: 'Asociados Mayoristas | Bithonor'
  //   }
  // },
  // {
  //   path: '/asociado-mayorista/tablero',
  //   name: 'WholeSalePartnerDashboard',
  //   component: () => import('../modules/wholesalePartners/views/Dashboard.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Asociados Mayoristas | Bithonor'
  //   }
  // },
  // {
  //   path: '/asociado-mayorista/solicitud-pagina',
  //   name: 'PageRequest',
  //   component: () => import('../modules/wholesalePartners/views/PageRequest.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Personaliza tu página | Bithonor'
  //   }
  // },
  // { 
  //   path: '/asociado-mayorista/solicitud',
  //   name: 'WholeSalePartnerRequest',
  //   component: () => import('../modules/verificationLevels/views/WholeSalePartnerRequest.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     baseLayout: true,
  //     title: 'Solicitud Asociado Mayorista | Bithonor'
  //   },
  // },
  // { 
  //   path: '/asociado-mayorista/solicitud/exitosa',
  //   name: 'WholeSalePartnerReqSuccess',
  //   component: () => import('../modules/verificationLevels/views/WholeSalePartnerReqSuccess.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     title: 'Solicitud Asociado Mayorista Exitosa | Bithonor'
  //   },
  // },
  // { 
  //   path: '/asociado-mayorista/solicitud-pagina/exitosa',
  //   name: 'PageCreationSuccess',
  //   component: () => import('../modules/wholesalePartners/views/PageCreationSuccess.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     title: 'Página creada exitosamente | Bithonor'
  //   },
  // },
  {
    path: '/referidos',
    name: 'Ambassador',
    component: () => import('../modules/ambassador/views/Ambassador.vue'),
    meta:{
      requiresAuth: true,
      baseLayout: true,
      title:  'Referidos | Bithonor'
    }
  },
  // {
  //   path: '/referidos/embajadores/solicitud-exitosa',
  //   name: 'AmbassadorRequestSuccess',
  //   component: () => import('../modules/ambassador/views/AmbassadorRequestSuccess.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     title:  'Referidos | Bithonor'
  //   }
  // },
  {
    path: '/enviar-dinero/:modo',
    name: 'SendRemittance',
    component: () => import('../modules/remittance/views/SendRemittance.vue'),
    meta:{
      baseLayout: true,
      requiresAuth: true,
      title: 'Enviar dinero | Bithonor'
    }
  },
  {
    path: '/enviar-dinero/envio-exitoso/:publicId',
    name: 'SendRemittanceSuccess',
    component: () => import('../modules/remittance/views/SendRemittanceSuccess.vue'),
    meta:{
      title: 'Envío exitoso | Bithonor'
    }
  },
  // {
  //   path: '/intercambios/:exchangeType/:publicId',
  //   name: 'SendExchangeSuccess',
  //   component: () => import('../modules/exchange/views/SendExchangeSuccess.vue'),
  //   meta:{
  //     title: 'Envío exitoso | Bithonor'
  //   }
  // },
  {
    path: '/historial/envios',
    name: 'RemittanceOperations',
    component: () => import('../modules/remittance/views/Operations.vue'),
    meta:{
      title: 'Operaciones | Bithonor',
      baseLayout: true,
      requiresAuth: true
    }
  },
  // {
  //   path: '/historial/intercambios',
  //   name: 'ExchangeOperations',
  //   component: () => import('../modules/exchange/views/Operations.vue'),
  //   meta:{
  //     title: 'Operaciones | Bithonor',
  //     baseLayout: true,
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/protected-route',
  //   name: 'ProtectedRouteExample',
  //   component: () => import('../views/ProtectedRouteExample.vue'),
  // },
  {
    path: '/error',
    name: 'ErrorPage',
    meta:{
      title: 'Ha ocurrido un error | Bithonor'
    },
    component: () => import('../views/ErrorPage.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    // beforeEnter: (to, from, next) => { next('/404') },
    meta: {
      title: 'Página no encontrada | Bithonor',
    }
  },
  {
    path: '/dominio-incorrecto',
    name: 'IncorrectDomain',
    component: () => import('../views/IncorrectDomain.vue'),
    meta:{
      baseLayout: false,
      title: 'Dominio incorrecto | Bithonor'
    }
  },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: () => import('../views/NotFound.vue'),
  //   meta:{
  //     title: 'Página no encontrada | Bithonor'
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a hash, scroll to the anchor element
    if (to.hash) {
      return { selector: to.hash };
    }

    // Otherwise, scroll to the top of the page
    return { x: 0, y: 0 };
  },
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  setMetaDescription(to.meta.description, to.meta.dynamicDescription);

  if (from.name === 'LogOut' && to.name === 'ErrorPage') {
    return;
  }

  for (const route of to.matched) {
    if (route.meta.requiresAuth) {
      if (!store.state.auth.isAuthenticated) {
        return next({ name: 'Login' });
      }

      // Redirección a Bithonor correcto
      const countryIsoCode = store.state.auth.user.iso_code_resid_country;
      const domain = window.location.hostname;
      const countryName = countries[countryIsoCode]?.es;

      if (countryName) {
        let incorrectDomainCondition =
          (isInAmerica(countryIsoCode) && domain.includes('.es')) ||
          (!isInAmerica(countryIsoCode) && domain.includes('.com'));

        if (incorrectDomainCondition) {
          await store.dispatch(`auth/${AuthMethods.actions.LOGOUT}`, store.state.auth.user.email_user);
          let redirectionParams = {
            name: 'IncorrectDomain',
            params: {
              isoCodeResidCountry: countryIsoCode,
              countryName,
              URL: isInAmerica(countryIsoCode) ? latamUrl() : europeUrl(),
              correctBithonor: isInAmerica(countryIsoCode) ? 'LATAM' : 'EUROPA',
              incorrectBithonor: isInAmerica(countryIsoCode) ? 'EUROPA' : 'LATAM'
            }
          };
          return next(redirectionParams);
        }
      }

      requiresPreceding(route, from, next);
    } else {
      if (to.path === '/registro') store.state.auth.userSignUp = clearUser(store.state.auth.userSignUp);
      if (store.state.auth.isAuthenticated && route.meta.authBlocked) {
        return next({ name: 'Dashboard' });
      }
      requiresPreceding(route, from, next);
    }
  }
});



/* If the route needs to be preceded by another route */
function requiresPreceding(to,from,next){
  if (to.meta.requiresPreceding){
    if (from.meta.nextRoute === to.path || from.meta.prevRoute === to.path)
      next()
    else 
      next({ path: to.meta.firstStep})
  }
  else next()
}

function setMetaDescription(description, dynamicDescription){
  const metaTag = document.querySelector('meta[name="description"]');
  if (dynamicDescription) return
  if (!description){ 
    if (metaTag) metaTag.remove()
    return
  }
  if (metaTag) {
    metaTag.setAttribute('content', description);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', 'description');
    newMetaTag.setAttribute('content', description);
    document.head.appendChild(newMetaTag);
  }
}

export default router;
