import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';
import { EnvironmentConstants } from './constants/enviromentConstants'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import dotenv from 'dotenv'
import VueClipboard from 'vue-clipboard2'
import VueApexCharts from 'vue-apexcharts'
// import VueRecord from '@codekraft-studio/vue-record'
import Vue2Filters from 'vue2-filters';
import VueNumeric from 'vue-numeric'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import IdleVue from 'idle-vue'
import Meta from 'vue-meta'
import * as Sentry from "@sentry/vue";


var Vue2FiltersConfig = { 
  currency: {
    symbol: '',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false
  }
}
dotenv.config()
const eventsHub = new Vue()
 
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, // 15 minutes // 900 seconds
  startAtIdle: false
});
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(VueNumeric)
Vue.use(Vue2Filters,Vue2FiltersConfig)
Vue.use(VueApexCharts)
Vue.use(Meta)
Vue.component('apexchart', VueApexCharts)
Vue.component(VueQrcode.name, VueQrcode);
let socket = io(EnvironmentConstants.SOCKETHOST, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Infinity,
  timeout: 20000
});

socket.on('connect', () => {
  console.log('Connected to server via sockets');
});

socket.on('disconnect', (reason) => {
  console.log('SOCKET DISCONNECT REASON: ',reason)
  if (reason === 'io server disconnect') {
    // Handle intentional server disconnection
  } else if (reason === 'ping timeout') {
    // Implement reconnection logic here
    socket = io(EnvironmentConstants.SOCKETHOST, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      timeout: 20000
    });
  } else if (reason === 'transport close') {
    // Implement reconnection logic here
    socket = io(EnvironmentConstants.SOCKETHOST, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      timeout: 20000
    });
  }
});

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Loading);
// Vue.use(VueRecord)
Vue.use(VueSocketIOExt, socket);  
Vue.use(Vuelidate)
Vue.use(VueToast, {
  position: 'top',
  duration: 4000
});
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Sentry.init({
  Vue,
  dsn: "https://37360fb32a5a97437da22067811f28bb@o4507568951001088.ingest.us.sentry.io/4507652805623808",
  environment: process.env.VUE_SENTRY_ENV,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration({
    networkDetailAllowUrls: [
      window.location.origin,
      'https://apibhdev.bithonor.com/socket.io/',
      'https://salesiq.zohopublic.com/visitor/v2/channels/website',
      'https://api.ipify.org'
    ],
    networkRequestHeaders: ["Cache-Control"],
    networkResponseHeaders: ["Referrer-Policy"],
  }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.config.productionTip = false
Vue.$toast.clear();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
