import { User } from "../interfaces/user.interface";

export const USER_EMPTY_STATE: User = {
    id_uuid: '',
    id_user: undefined,
    first_name : '',
    last_name : '',
    username: '',
    password: '',
    second_last_name: '',
    email_user: '',
    gender: '',
    date_birth: '',
    ident_doc_number: '',
    main_phone: '',
    main_phone_wha: false,
    second_phone: '',
    second_phone_wha: false,
    delegated_phone: '',
    delegated_phone_wha: false,
    resid_city: '',
    departments: '',
    id_ident_doc_type: '',
    id_resid_country: '',
    id_nationality_country: '',
    name_nationality_country: '',
    name_residence_country: '',
    cust_cr_cod_pub: '',
    cod_rank: 'Bronce',
    verif_level_apb: false,
    multi_country: false,
    address: '',
    referral_node: '',
    main_sn_platf: '',
    user_main_sn_platf: '',
    ok_legal_terms: false,
    date_legacy_reg: '',
    uuid_profile: '',
    id_service: '',
    id_services_utype: '',
    id_verif_level: '',
    main_phone_code: '',
    main_phone_full: '',
    main_phone_country_code: null,
    second_phone_code: '',
    second_phone_full: '',
    delegated_phone_code: '',
    delegated_phone_full: '',
    state_name: '',
    pol_exp_per: null,
    occupation: '',
    doc: '',
    selfie: '',
    captcha: undefined,
    iso_code_nationality_country: '',
    iso_code_resid_country: '',
    wallets: null,
    wholesale_partner_info: null,
    /* MIGRATED DATA */
    id_migrated: null,
    country_exception: null,
    limit_exception: null,
    phone_exception: null,
    multi_currency_exception: null,
    completed_information_migrated: null,
    extra_info: null
}

export function clearUser(user: User) : User{
    user.first_name = '',
    user.last_name = '',
    user.username= '',
    user.password= '',
    user.second_last_name= '',
    user.email_user= '',
    user.gender= '',
    user.date_birth= '',
    user.ident_doc_number= '',
    user.main_phone= '',
    user.main_phone_wha= '',
    user.second_phone= '',
    user.second_phone_wha= false,
    user.delegated_phone= '',
    user.delegated_phone_wha= false,
    user.resid_city= '',
    user.departments= '',
    user.id_ident_doc_type= '',
    user.id_resid_country= '',
    user.id_nationality_country= '',
    user.name_nationality_country= '',
    user.name_residence_country= '',
    user.cust_cr_cod_pub= '',
    user.cod_rank= 'Bronce',
    user.verif_level_apb= false,
    user.multi_country= false,
    user.address= '',
    user.referral_node= '',
    user.main_sn_platf= '',
    user.user_main_sn_platf= '',
    user.ok_legal_terms= false,
    user.date_legacy_reg= '',
    user.uuid_profile= '',
    user.id_service= '',
    user.id_services_utype= '',
    user.id_verif_level= '',
    user.main_phone_code= '',
    user.main_phone_full= '',
    user.second_phone_code= '',
    user.second_phone_full= '',
    user.delegated_phone_code= '',
    user.delegated_phone_full= '',
    user.state_name= '',
    user.pol_exp_per= null,
    user.occupation= '',
    user.doc= '',
    user.selfie= '',
    user.captcha= undefined,
    user.iso_code_nationality_country= '',
    user.iso_code_resid_country= '',
    user.wallets= null
    return user
}