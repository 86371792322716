import { Module } from 'vuex';
import VerifLevelsMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { VERIF_LEVELS_EMPTY_STATE } from './verificationLevels.state';
import { VerifLevelsStateInterface } from './interfaces/verificationLevels.interface';
import { verifLevelHttpRepository } from '@/modules/verificationLevels/repositories/verifLevels.repository';
import { Question } from '@/modules/verificationLevels/interfaces/questions.interface';
import { ReqLevelOne, ReqLevelTwo, RequestStatus, ReqWholeSaleParter, EditPhone, UpdateLevelOneInfo, SaveExtraInfoThirdModal } from '@/modules/verificationLevels/interfaces/requests.interface';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import { MigrationStatus } from '@/modules/verificationLevels/interfaces/migrationStatus.interface';
import { NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { Limitation, LimitationType } from '@/modules/verificationLevels/interfaces/limitations.interface';
import { VerifLevelReq, WholeSalePartnerReq } from '@/modules/verificationLevels/interfaces/requirement.interface';
import store from '..';
import { ALT_DOC_TYPES } from '@/modules/docTypes/constants/docTypes.contants';
import { isoDateToDDMMYYYY, spanishDateToEnglishDate } from '@/utils/date-functions';
import { User } from '@/modules/auth/interfaces/user.interface';
import { MigratedData } from '@/modules/verificationLevels/interfaces/migrated.interface';
import { LEVEL_ONE_REQUEST_EMPTY_STATE, LEVEL_TWO_REQUEST_EMPTY_STATE, resetLevelOneRequest, resetLevelTwoRequest } from '@/modules/verificationLevels/emptyStates/requests.emptyState';

const verifLevels: Module<VerifLevelsStateInterface, any> = {
    namespaced: true,
    state: VERIF_LEVELS_EMPTY_STATE,
    getters: {
        [VerifLevelsMethods.getters.GET_LEVELS_QUESTIONS](state): Question[]{
            return state.levelQuestions
        },
        [VerifLevelsMethods.getters.GET_AVAILABLE_RESID_COUNTRIES](state): NationalityCountry[]{
            return state.wholesaleResidCountries
        },
        [VerifLevelsMethods.getters.GET_MIGRATION_STATUS](state): MigrationStatus[]{
            return state.migrationStatus
        },
        [VerifLevelsMethods.getters.GET_LIMITATIONS_BY_COUNTRY](state): any{ //{limitations: Limitation[], name_limitations: LimitationType[]}
            return state.limitations
        },
        [VerifLevelsMethods.getters.GET_VERIF_LEVEL_REQUIREMENTS](state): any{ //{level_one: VerifLevelReq[], level_two: VerifLevelReq[], email_user: string}
            return state.verifLevelReq
        },
        [VerifLevelsMethods.getters.GET_WHOLESALEPARTNER_REQUIREMENTS](state): any{ //{questions: WholeSalePartnerReq[],email_user: string} 
            return state.wholeSalePartnerReq
        },
        [VerifLevelsMethods.getters.GET_LEVEL_ONE_REQUEST](state): ReqLevelOne{ 
            return state.levelOneRequest
        },
        [VerifLevelsMethods.getters.GET_LEVEL_TWO_REQUEST](state): ReqLevelTwo{
            return state.levelTwoRequest
        },
        [VerifLevelsMethods.getters.GET_LEVEL_ONE_REQUEST_STATUS](state): RequestStatus{ 
            return state.levelOneRequestStatus
        },
        [VerifLevelsMethods.getters.GET_LEVEL_TWO_REQUEST_STATUS](state): RequestStatus{ 
            return state.levelTwoRequestStatus
        },
        [VerifLevelsMethods.getters.GET_MIGRATED_LEVEL_ONE_DATA](state): MigratedData{ 
            return state.migratedData
        }
    },
    mutations: {
        [VerifLevelsMethods.mutations.SET_LEVELS_QUESTIONS](state, questions : Question[]): void {
            state.levelQuestions = questions;
        },
        [VerifLevelsMethods.mutations.SET_AVAILABLE_RESID_COUNTRIES](state, wholesaleResidCountries : NationalityCountry[]): void {
            state.wholesaleResidCountries = wholesaleResidCountries;
        },
        [VerifLevelsMethods.mutations.SET_MIGRATION_STATUS](state, status : MigrationStatus[]): void {
            state.migrationStatus = status;
        },
        [VerifLevelsMethods.mutations.SET_LIMITATIONS_BY_COUNTRY](state, limitations : {limitations: Limitation[], name_limitations: LimitationType[]}): void {
            state.limitations = limitations;
        },
        [VerifLevelsMethods.mutations.SET_VERIF_LEVEL_REQUIREMENTS](state, req : {level_one: VerifLevelReq[], level_two: VerifLevelReq[], email_user: string}): void {
            state.verifLevelReq = req;
        },
        [VerifLevelsMethods.mutations.SET_WHOLESALEPARTNER_REQUIREMENTS](state, req : {questions: WholeSalePartnerReq[], email_user: string}): void {
            state.wholeSalePartnerReq = req;
        },
        [VerifLevelsMethods.mutations.SET_LEVEL_ONE_REQUEST](state, req : ReqLevelOne): void {
            state.levelOneRequest = req;
        },
        [VerifLevelsMethods.mutations.SET_LEVEL_ONE_REQUEST_DATA](state, req : VerifLevelReq[]): void {
            if (req !== null){
                req.forEach(el =>
                {
                    if (el)
                    {
                        if (el.req_use_path !== "")
                        {
                            state.levelOneRequest[el.req_type].value = el.req_use_path
                        }
                        else
                        {
                            if (el.req_type === 'address')
                                state.levelOneRequest.domicile_address.value = el.req_type_value
                            else
                                state.levelOneRequest[el.req_type].value = el.req_type_value
                        }
                        if (el.req_confirm_action === null) state.levelOneRequestStatus = RequestStatus.UNDER_REVIEW
                        else if (el.req_confirm_action === 'false')
                        {
                            state.levelOneRequestStatus = RequestStatus.REJECTED
                            if (el.req_type === 'address')
                                state.levelOneRequest.domicile_address.rejected = true
                            else
                                state.levelOneRequest[el.req_type].rejected = true;
                        }
                        else if (el.req_confirm_action === 'true')
                            if (el.req_type === 'address')
                                state.levelOneRequest.domicile_address.rejected = false
                            else
                                state.levelOneRequest[el.req_type].rejected = false;
                    }
                })
                if (state.levelOneRequestStatus === RequestStatus.BLANK) state.levelOneRequestStatus = RequestStatus.APPROVED
                // if (state.levelOneRequest.pol_exp_per.value.length >0) 
                //     state.levelOneRequest.pol_exp_per.value = JSON.parse(state.levelOneRequest.pol_exp_per.value)
                if (state.levelOneRequest.user_main_sn_platf.value.length && state.levelOneRequest.user_main_sn_platf.value[0] === '@'){ 
                    state.levelOneRequest.user_main_sn_platf.value = state.levelOneRequest.user_main_sn_platf.value.substring(1)}
                    // this.levelOneRequirements.date_birth.value = isoDateToDDMMYYYY(this.levelOneRequirements.date_birth.value)
                let user : User = store.state.auth.user;
                state.levelOneRequest.date_birth.value = isoDateToDDMMYYYY(user.date_birth)
                state.levelOneRequest.date_birth.rejected = false
                state.levelOneRequest.id_nationality_country.value = user.id_nationality_country
                state.levelOneRequest.id_nationality_country.rejected = false
                state.levelOneRequest.gender.value = user.gender
                state.levelOneRequest.gender.rejected = false
                state.levelOneRequest.lawful_funds.value = user.lawful_funds
                state.levelOneRequest.lawful_funds.rejected = false
                state.levelOneRequest.pol_exp_per.value = user.pol_exp_per
                state.levelOneRequest.pol_exp_per.rejected = false
                state.levelOneRequest.truthful_information.value = user.truthful_information
                state.levelOneRequest.truthful_information.rejected = false
                state.levelOneRequest.main_phone.value = user.main_phone
                state.levelOneRequest.main_phone.rejected = false
                state.levelOneRequest.main_phone_code.value = user.main_phone_code
                state.levelOneRequest.main_phone_code.rejected = false
                state.levelOneRequest.main_phone_full.value = user.main_phone_full
                state.levelOneRequest.main_phone_full.rejected = false
                if (state.levelOneRequest.doc.rejected === true){ 
                    state.levelOneRequest.doc.value = null
                }
                if (state.levelOneRequest.selfie.rejected === true){ 
                    state.levelOneRequest.selfie.value = null
                }
            }
        },
        [VerifLevelsMethods.mutations.SET_LEVEL_TWO_REQUEST_DATA](state, req : VerifLevelReq[]): void {
            if (req !== null){
                req.forEach(el =>{
                    if (el.req_use_path !== "")
                        state.levelTwoRequest[el.req_type].value = el.req_use_path
                    else
                        state.levelTwoRequest[el.req_type].value = el.req_type_value
                    // if (el.req_confirm_action === null) state.alreadyRequested = true;
                    // else 
                    if (el.req_confirm_action === null) state.levelTwoRequestStatus = RequestStatus.UNDER_REVIEW
                    if (el.req_confirm_action === 'false') {
                        state.levelTwoRequest[el.req_type].rejected = true;
                        state.levelTwoRequestStatus = RequestStatus.REJECTED
                    }
                    else if (el.req_confirm_action === 'true')
                        state.levelTwoRequest[el.req_type].rejected = false;
                })
                if (state.levelTwoRequestStatus === RequestStatus.BLANK) state.levelTwoRequestStatus = RequestStatus.APPROVED
                if (state.levelTwoRequest.residency_proof.rejected === true){ 
                    state.levelTwoRequest.residency_proof.value = null
                }
            }
        },
        [VerifLevelsMethods.mutations.SET_MIGRATED_LEVEL_ONE_DATA](state, data : MigratedData): void {
            state.migratedData = data;
            state.levelOneRequest.migrated = true;
            // state.levelOneRequest.domicile_address.value = data.address;
            state.levelOneRequest.date_birth.value = isoDateToDDMMYYYY(data.date_birth);
            state.levelOneRequest.gender.value = data.gender;
            // state.levelOneRequest.ident_doc_number.value = data.doc_number;
            // state.levelOneRequest.id_res.value = data.id_migrated_country;
            state.levelOneRequest.id_nationality_country.value = data.id_nationality_country;
            // state.levelOneRequest.main_phone_full.value = data.main_phone_full;
            // state.levelOneRequest.main_phone_code.value = data.main_phone_code;
            // state.levelOneRequest.main_phone.value = data.main_phone_full.replace(data.main_phone_code,'');
            state.levelOneRequest.main_sn_platf.value = 'Instagram'
            // state.levelOneRequest.user_main_sn_platf.value = data.user_main_sn_platf;
            state.levelOneRequest.occupation.value = data.occupation;
            state.levelOneRequest.state_name.value = data.state_name;
        },
        [VerifLevelsMethods.mutations.RESET_VERIF_LEVEL_DATA](state): void {
            state.levelQuestions= [];
            state.wholesaleResidCountries= [];
            state.migrationStatus = [];
            state.limitations.limitations = []
            state.limitations.name_limitations = []
            state.verifLevelReq.level_one = []
            state.verifLevelReq.level_two = []
            state.verifLevelReq.email_user = ''
            state.wholeSalePartnerReq.questions = []
            state.wholeSalePartnerReq.email_user = ''
            state.levelOneRequest= resetLevelOneRequest(state.levelOneRequest)
            state.levelOneRequest.doc.value = null
            state.levelTwoRequest= resetLevelTwoRequest(state.levelTwoRequest)
            state.levelOneRequestStatus= RequestStatus.BLANK;
            state.levelTwoRequestStatus= RequestStatus.BLANK;
            state.migratedData= null
        },
    },
    actions: {
        async [VerifLevelsMethods.actions.FETCH_LEVELS_QUESTIONS]({ commit },idResidCountry: number): Promise<boolean> {
            try {
                let response : Question[] = await verifLevelHttpRepository.getLevelQuestions(idResidCountry);
                if (response.length > 0){
                    commit(VerifLevelsMethods.mutations.SET_LEVELS_QUESTIONS,response);
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_MIGRATION_STATUS]({ commit }): Promise<boolean> {
            try {
                let response : MigrationStatus[] = await verifLevelHttpRepository.getMigrationStatus()
                if (response.length > 0){
                    commit(VerifLevelsMethods.mutations.SET_MIGRATION_STATUS,response);
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_AVAILABLE_RESID_COUNTRIES]({ commit }): Promise<boolean> {
            try {
                let response : NationalityCountry[] = await verifLevelHttpRepository.getAvailableResidCountries();
                if (response.length > 0){
                    commit(VerifLevelsMethods.mutations.SET_AVAILABLE_RESID_COUNTRIES,response);
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.REQUEST_LEVEL_ONE]({ commit }, data: ReqLevelOne): Promise<boolean> {
            try
            {
                let docFile = undefined;
                let selfieFile = undefined;
                if (data.doc.value.name){ docFile = new File([data.doc.value], 'req-level-1-doc.png',{type: data.doc.value.type});}
                else{
                    let blob1 = new Blob([new Uint8Array(data.doc.value.data)])
                    docFile = new File([blob1], 'req-level-1-doc.png',{type:'image/png'})
                }
                if (data.selfie.value.name){ selfieFile = new File([data.selfie.value], 'req-level-1-selfie.png',{type: data.selfie.value.type});}
                else{
                    let blob1 = new Blob([new Uint8Array(data.selfie.value.data)])
                    selfieFile = new File([blob1], 'req-level-1-selfie.png',{type:'image/png'})
                }
                let mainPlatf = null
                if (data.main_sn_platf.value !== 'Ninguna') mainPlatf = data.main_sn_platf.value
                let englishDate = spanishDateToEnglishDate(data.date_birth.value)
                let bodyFormData = new FormData();
                bodyFormData.append('date_birth', englishDate);
                bodyFormData.append('state_name', data.state_name.value);
                if (data.migrated) bodyFormData.append('resid_city', `${data.resid_city.value} - ${data.residCountry?.country_iso_code}`);
                else bodyFormData.append('resid_city', data.resid_city.value);
                if (data.pol_exp_per.value) 
                    bodyFormData.append('pol_exp_per', data.pol_exp_per.value.toString());
                else bodyFormData.append('pol_exp_per', 'false');
                if (data.truthful_information.value) 
                    bodyFormData.append('truthful_information', data.truthful_information.value.toString());
                else bodyFormData.append('truthful_information', 'false');
                if (data.lawful_funds.value) 
                    bodyFormData.append('lawful_funds', data.lawful_funds.value.toString());
                else bodyFormData.append('lawful_funds', 'false');
                if (data.migrated){ 
                    if (data.ok_legal_terms)
                        bodyFormData.append('ok_legal_terms', data.ok_legal_terms.value.toString());
                    else bodyFormData.append('ok_legal_terms', 'false');
                    bodyFormData.append('new_password',data.new_password.value)
                    // bodyFormData.append('new_email',data.new_email.value)
                }
                bodyFormData.append('email_user', data.email_user.value);
                if (data.id_ident_doc_type.value >=1)
                    bodyFormData.append('id_ident_doc_type', data.id_ident_doc_type.value.toString());
                else 
                    bodyFormData.append('id_country',data.id_country.value.toString());
                bodyFormData.append('ident_doc_number', data.ident_doc_number.value);
                bodyFormData.append('id_nationality_country', data.id_nationality_country.value.toString());
                bodyFormData.append('main_phone', data.main_phone.value);
                bodyFormData.append('main_phone_code', data.main_phone_code.value);
                bodyFormData.append('main_phone_full', data.main_phone_full.value);
                bodyFormData.append('occupation', data.occupation.value);
                bodyFormData.append('gender', data.gender.value);
                bodyFormData.append('doc', docFile);
                bodyFormData.append('selfie', selfieFile);
                bodyFormData.append('main_sn_platf', mainPlatf);
                bodyFormData.append('user_main_sn_platf', data.user_main_sn_platf.value);
                bodyFormData.append('domicile_address', data.domicile_address.value);
                let response: {message?: string} = undefined;
                if (data.id_ident_doc_type.value >=1)
                    response = await verifLevelHttpRepository.requestLevelOneFirstQuestion(bodyFormData);
                else if (data.id_ident_doc_type.value === ALT_DOC_TYPES.PASSPORT.id)
                    response = await verifLevelHttpRepository.requestLevelOneSecondQuestion(bodyFormData);
                else if (data.id_ident_doc_type.value === ALT_DOC_TYPES.ID.id)
                    response = await verifLevelHttpRepository.requestLevelOneThirdQuestion(bodyFormData);
                if (response.message === "Request succesfuly uploaded."){ 
                    if (data.migrated) store.state.auth.user.completed_information_migrated = true
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.UPDATE_CLIENT_PHONE]({ commit }, data: EditPhone): Promise<boolean> {
            try
            {
                await verifLevelHttpRepository.editClientPhone(data);
                return true
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.UPDATE_LEVEL_ONE_INFO]({ commit }, data: UpdateLevelOneInfo): Promise<boolean> {
            try
            {
                const payload = {
                    state_name: data.state_name,
                    resid_city: data.resid_city,
                    occupation: data.occupation,
                    email_user: data.email_user,
                    address: data.address
                };
                await verifLevelHttpRepository.updateLevelOneInfo(payload);
                return true
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.SAVE_EXTRA_INFO_THIRD_MODAL]({ commit }, data: SaveExtraInfoThirdModal): Promise<boolean> {
            try
            {
                console.log('data en STORE',data)
                const payload = {
                    id_user: data.id_user,
                    industry: data.industry,
                    salary_range: data.salary_range,
                };
                const resp = await verifLevelHttpRepository.saveExtraInfoThirdModal(payload);
                console.log('resp',resp)
                store.state.auth.user.extra_info = resp.extra_data
                return true
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.REQUEST_LEVEL_TWO]({ commit }, requirements: ReqLevelTwo): Promise<boolean> {
            try {
                let residencyProofFile = undefined;
                if (requirements.residency_proof.value.name){
                    residencyProofFile = new File([requirements.residency_proof.value], 'req-level-2.png',{type: requirements.residency_proof.value.type});
                }
                else{
                    let blob1 = new Blob([new Uint8Array(requirements.residency_proof.value.data)])
                    residencyProofFile = new File([blob1], 'req-level-2.png',{type:'image/png'})
                }
                let bodyFormData = new FormData();
                // Object.keys(requirements).forEach(el =>{
                //     bodyFormData.append(el,typeof requirements[el] === 'object' ? JSON.stringify(requirements[el]) : requirements[el].value)
                // })
                bodyFormData.append('email_user', requirements.email_user.value)
                bodyFormData.append('job_title', requirements.job_title.value)
                bodyFormData.append('answers', JSON.stringify(requirements.answers))
                // bodyFormData.append('funds_source', requirements.funds_source.value)
                // bodyFormData.append('other_industry', requirements.other_industry.value)
                bodyFormData.append('residency_proof',residencyProofFile)//,requirements.residency_proof.value)
                let response : any = await verifLevelHttpRepository.requestLevelTwo(bodyFormData);
                if (response.message === "Request succesfuly uploaded."){
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.REQUEST_WHOLESALE_PARTNER]({ commit }, requirements: ReqWholeSaleParter): Promise<{message: string, error: boolean}> {
            try {
                let response : any = await verifLevelHttpRepository.requestWholeSale(requirements);
                if (response.msg === "Wholesale partner request has been registred succesfully"){
                    return {message: 'Solicitud enviada exitosamente', error: false};
                }
                return {message: 'Ha ocurrido un error enviando su solicitud', error: true};
            } catch (e) {
                return {message: 'Ha ocurrido un error enviando su solicitud', error: true};;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_LIMITATION_BY_COUNTRY]({ commit }, countryId: string): Promise<boolean> {
            try {
                let response : {limitations: Limitation[], name_limitations: LimitationType[]} = await verifLevelHttpRepository.getLimitationsByCountryId(countryId)
                if (response.limitations.length && response.name_limitations.length){
                    commit(VerifLevelsMethods.mutations.SET_LIMITATIONS_BY_COUNTRY,response)
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_VERIF_LEVEL_REQUIREMENTS]({ commit }, userEmail: string): Promise<boolean> {
            try {
                let response = await verifLevelHttpRepository.getVerifLevelRequirements(userEmail)
                if (response.email_user)
                {
                    store.state.auth.user = response.user
                    /* MIGRADOS */
                    // store.state.auth.user.id_migrated = true
                    // store.state.auth.user.completed_information_migrated = false
                    commit(VerifLevelsMethods.mutations.SET_VERIF_LEVEL_REQUIREMENTS, response)
                    commit(VerifLevelsMethods.mutations.SET_LEVEL_ONE_REQUEST_DATA, response.level_one)
                    commit(VerifLevelsMethods.mutations.SET_LEVEL_TWO_REQUEST_DATA, response.level_two)
                    return true
                }
                return false
            } catch (e)
            {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_WHOLESALEPARTNER_REQUIREMENTS]({ commit }, userEmail: string): Promise<boolean> {
            try {
                let response: {questions: WholeSalePartnerReq[], email_user: string}  = await verifLevelHttpRepository.getWholeSalePartnerRequirements(userEmail);
                if (response.email_user){
                    commit(VerifLevelsMethods.mutations.SET_WHOLESALEPARTNER_REQUIREMENTS,response)
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [VerifLevelsMethods.actions.FETCH_MIGRATED_LEVEL_ONE_DATA]({ commit }, idMigrated: number): Promise<boolean> {
            try {
                let response: MigratedData  = await verifLevelHttpRepository.getMigratedData(idMigrated);
                
                if (response){
                    commit(VerifLevelsMethods.mutations.SET_MIGRATED_LEVEL_ONE_DATA,response)
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        },
    },
};

export default verifLevels;